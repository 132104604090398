<template>
  <div class="col-md-12 mb-3">
    <h5>
      <div class="form-subheader">
        <span>RELAÇÃO DE CAPACIDADE NO MERCADO</span>
      </div>
    </h5>
    <div class="row mb-4">
      <div class="col-md-12 mb-3">
        <div class="d-flex mb-3 align-items-end justify-content-between">
          <label class="dbo-ui mb-lg-0 font-weight-bold ml-2"
            >SEGURADORAS</label
          >
          <a
            @click="isFormDisabled ? null : $bvModal.show('show-modal-insurer')"
            class="button btn btn-outline-secondary btn-sm"
            :class="{ 'disabled-button': isFormDisabled }"
            :disabled="isFormDisabled"
            :title="
              isFormDisabled ? 'Preencha os campos de Dados do Tomador' : ''
            "
          >
            <i class="fa fa-plus mr-1"></i> Adicionar seguradora
          </a>
        </div>
        <div class="table-responsive">
          <b-table
            class="table pretty-title-gray mb-0 financial-statement-table"
            style="background-color: #f8f8f8"
            :items="itemsInsurer"
            :fields="fields"
          >
            <template #cell(name)="data">
              <span class="centered-cell">{{ data.value }}</span>
            </template>
            <template #cell(status)="data">
              <span
                class="btn btn-sm btn-success text-white text-uppercase rounded-pill nowrap status-badge"
                v-if="data.value == 1"
              >
                Aprovado
              </span>
              <span
                class="btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap status-badge"
                v-if="data.value == 2"
              >
                Em Análise
              </span>
              <span
                class="btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap status-badge-documentation"
                v-if="data.value == 3"
              >
                Documentação pendente
              </span>
              <span
                class="btn btn-sm btn-danger text-white text-uppercase rounded-pill nowrap status-badge"
                v-if="data.value == 4"
              >
                Recusada
              </span>
              <span
                class="btn btn-sm btn-primary text-white text-uppercase rounded-pill nowrap status-badge"
                v-if="data.value == 5"
              >
                Nomeada
              </span>
            </template>
            <template #cell(approved_limit)="data">
              <span class="centered-cell">{{
                formatCurrency(data.value) ?? ""
              }}</span>
            </template>
            <template #cell(available_limit)="data">
              <span class="centered-cell">{{
                formatCurrency(data.value) ?? ""
              }}</span>
            </template>
            <template #cell(total_accumulation)="data">
              <span class="centered-cell">{{
                formatCurrency(data.value) ?? ""
              }}</span>
            </template>
            <template #cell(modality)="data">
              <div class="centered-cell">
                <span class="nowrap col-md-10">{{
                  data.value == 1 ? "Judicial" : "Tradicional"
                }}</span>
              </div>
            </template>
            <template #cell(current_rate)="data">
              <span class="centered-cell">{{
                formatPercentage(data.value) ?? ""
              }}</span>
            </template>
            <template #cell(validity)="data">
              <span class="centered-cell">
                {{ data.value != "" ? formatDateLocal(data.value) : "- - -" }}
              </span>
            </template>
            <template #cell(ccg)="data">
              <div class="centered-cell">
                <span
                  class="btn btn-sm text-white text-uppercase rounded-pill nowrap col-md-10"
                  :class="data.value === 1 ? 'btn-success' : (data.value === 0 ? 'btn-orange' : 'btn-secondary')"
                >
                  {{ data.value === 1 ? "Regularizado" : (data.value === 0 ? "Não regularizado" : "Não informado") }}
                </span>
              </div>
            </template>
            <template #cell(appointment)="data">
              <div class="centered-cell">
                <span
                  class="btn btn-sm text-white text-uppercase rounded-pill nowrap col-md-10"
                  :class="data.value == 1 ? 'btn-success' : 'btn-danger'"
                >
                  {{ data.value == 1 ? "Sim" : "Não" }}
                </span>
              </div>
            </template>
            <template #cell(action)="row">
              <a class="text-secondary" @click="$emit('deleteInsurer', row)">
                <i class="fas fa-times mx-1 icon-btn"></i>
              </a>
              <a
                class="text-secondary"
                @click="$emit('showInsurer', row.index)"
              >
                <i class="fas fa-pen mx-1 icon-btn"></i>
              </a>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commomsHelperMixin from "@/mixins/commomsHelper";
import numericFormatterMixin from "@/mixins/numericFormatter";
import dateFormatterMixin from "@/mixins/dateFormatter";
export default {
  mixins: [commomsHelperMixin, numericFormatterMixin, dateFormatterMixin],
  props: {
    itemsInsurer: {
      type: Array,
      required: true,
    },
    isFormDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "name",
          label: "NOME",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "status",
          label: "Status",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "approved_limit",
          label: "LIMITE APROVADO",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "available_limit",
          label: "LIMITE DISPONÍVEL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "total_accumulation",
          label: "ACÚMULO TOTAL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "modality",
          label: "Modalidade",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "current_rate",
          label: "TAXA ATUAL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "validity",
          label: "VALIDADE",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "ccg",
          label: "CCG",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "action",
          label: "",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
      ];
    },
  },
};
</script>

<style scoped>
.centered-cell {
  text-align: center;
}
.status-badge {
  padding: 5px 10px;
  font-size: 12px;
}
</style>
