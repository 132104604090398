<template>
  <div class="modal">
    <b-modal
      id="show-modal-insurer"
      ref="showModalInsurer"
      @show="clearNewInsurerForm"
      @hide="modalInsurerHide"
      size="lg"
      class="mt-4"
      hide-footer
    >
      <br />
      <template #modal-title>
        <h5 class="modal-title">Adicionar seguradora</h5>
      </template>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form
          class="form"
          autocomplete="off"
        >
          <div class="col-11 mb-3 ml-4">
            <div class="text-center">
              <div class="pill-radio mb-4">
                <div class="pill-radio-item" @click="changeIndividual">
                  <input :checked="true" type="radio" name="tipo_pessoa" id="tipo-pessoa-fisica" value="pessoa-fisica">
                  <label for="tipo-pessoa-fisica" style="margin-bottom: 0px !important;">
                    Cadastro Individual
                  </label>
                </div>
                <div class="pill-radio-item active" @click="changeMultiple">
                  <input type="radio" name="tipo_pessoa" id="tipo-pessoa-juridica" value="pessoa-juridica" :checked="false">
                  <label for="tipo-pessoa-juridica" style="margin-bottom: 0px !important;">
                    Cadastro em Lote
                  </label>
                </div>
              </div>
            </div>
            <div v-if="!batch_registration">
              <h5 class="mb-3 mt-5">
                <div class="form-subheader">
                  <span>DADOS BÁSICOS</span>
                </div>
              </h5>
              <div class="mb-4">
                <validation-provider
                  name="nome da seguradora"
                  rules="required"
                  v-slot="validationContext"
                  vid="name"
                >
                  <label class="dbo-ui">nome</label>
                  <b-form-select v-model="dataFormInsurer.name" class="mb-3">
                  <b-form-select-option value="">Selecione a seguradora</b-form-select-option>
                  <b-form-select-option
                    v-for="(value, index) in insurers"
                    :value="value"
                    :key="index"
                  >
                    {{ value }}
                  </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="mb-4">
                <validation-provider
                  name="status"
                  rules="required"
                  v-slot="validationContext"
                  vid="status"
                >
                  <label class="dbo-ui">Status</label>
                  <b-form-select v-model="dataFormInsurer.status" @change="selectRules" class="mb-3">
                  <b-form-select-option value="">Sem Status</b-form-select-option>
                  <b-form-select-option
                    v-for="(value, index) in insurer_status"
                    :value="value.id"
                    :key="index"
                  >
                    {{ value.translate }}
                  </b-form-select-option>
                </b-form-select>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="row pb-3 mb-2">
                <div class="col-6">
                  <label class="dbo-ui">Modalidade da seguradora :</label>
                  <div class="row">
                    <b-form-radio
                      v-model="dataFormInsurer.modality"
                      value="1"
                      class="ml-3"
                      >Judicial</b-form-radio
                    >
                    <b-form-radio
                      v-model="dataFormInsurer.modality"
                      value="0"
                      class="ml-4"
                      >Tradicional</b-form-radio
                    >
                  </div>
                </div>
              </div>
              <h5 class="mb-3">
                <div class="form-subheader">
                  <span>limite</span>
                </div>
              </h5>
              <div class="row">
                <div class="col-12 col-md-6 mb-3">
                  <validation-provider
                    name="limite aprovado"
                    :rules="rules"
                    v-slot="validationContext"
                    vid="approved_limit"
                  >
                    <label class="dbo-ui">limite aprovado </label>
                    <money
                      v-model="dataFormInsurer.approved_limit"
                      v-bind="money"
                      placeholder="R$ 0,00"
                      :state="getValidationState(validationContext)"
                      class="col-12 form-control"
                    />
                    <div
                      v-if="dataFormInsurer.approved_limit < 0.01 && dataFormInsurer.status == 1"
                      class="text-danger"
                      >
                        O campo limite aprovado é obrigatório.
                    </div>
                  </validation-provider>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <validation-provider
                    name="limite disponivel"
                    :rules="rules"
                    v-slot="validationContext"
                    vid="available_limit"
                  >
                    <label class="dbo-ui">limite disponível </label>
                    <money
                      v-model="dataFormInsurer.available_limit"
                      v-bind="money"
                      placeholder="R$ 0,00"
                      :state="getValidationState(validationContext)"
                      class="col-12 form-control"
                    />
                    <div
                      v-if="dataFormInsurer.available_limit < 0.01 && dataFormInsurer.status == 1"
                      class="text-danger"
                    >
                      O campo limite disponivel é obrigatório.
                    </div>
                  </validation-provider>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <validation-provider
                    name="acumulo"
                    :rules="rules"
                    v-slot="validationContext"
                    vid="total_accumulation"
                  >
                    <label class="dbo-ui">acúmulo total </label>
                    <money
                      v-model="dataFormInsurer.total_accumulation"
                      v-bind="money"
                      placeholder="R$ 0,00"
                      :state="getValidationState(validationContext)"
                      class="col-12 form-control"
                    />
                    <div
                      v-if="dataFormInsurer.total_accumulation   < 0.01 && dataFormInsurer.status == 1"
                      class="text-danger"
                    >
                      O campo acúmulo total é obrigatório.
                    </div>
                  </validation-provider>
                </div>
                <div class="col-6 col-md-3 mb-3">
                  <validation-provider
                    name="taxa atual"
                    :rules="rule"
                    v-slot="validationContext"
                    vid="current_rate"
                  >
                    <label class="dbo-ui">taxa atual </label>
                    <b-input-group size="" prepend="" append="%">
                      <vue-regex-input
                        v-model="dataFormInsurer.current_rate"
                        :regExp="/^\d{0,3}(\.\d{0,20})?$/g"
                        :state="getValidationState(validationContext)"
                        class="form-control"
                        placeholder="0.00"
                      ></vue-regex-input>
                    </b-input-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-md-3 col-md-3 mb-3 px-2 ml-2">
                  <validation-provider
                    name="validade"
                    :rules="rule"
                    v-slot="validationContext"
                    vid="validity"
                  >
                  <label class="dbo-ui">validade</label>
                  <b-form-input
                    type="date"
                    v-model="dataFormInsurer.validity"
                    class="col-12"
                  ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
              <h5 class="my-3">
                <div class="form-subheader">
                  <span>CCG e Carta de Nomeação</span>
                </div>
              </h5>
              <div class="row pb-3">
                <div class="col-6">
                  <label class="dbo-ui">CCG regularizado?</label>
                  <div class="row">
                    <b-form-radio
                      v-model="dataFormInsurer.ccg"
                      :value="1"
                      class="ml-3"
                    >
                      Sim
                    </b-form-radio>
                    <b-form-radio
                      v-model="dataFormInsurer.ccg"
                      :value="0"
                      class="ml-4"
                    >
                      Não
                    </b-form-radio>
                    <b-form-radio
                      v-model="dataFormInsurer.ccg"
                      :value="null"
                      class="ml-4"
                    >
                      Não informado
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-12 mb-3">
                  <validation-provider
                    name="limite aprovado"
                    :rules="rules"
                    v-slot="validationContext"
                    vid="insurer_details"
                  >
                    <label class="dbo-ui">Detalhes das Seguradoras</label>
                    <textarea
                      style="height: 300px"
                      v-model="dataFormInsurer.insurer_details"
                      class="col-12 form-control"
                      :state="getValidationState(validationContext)"
                    ></textarea>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
          <div class="row border-top mt-4">
            <div class="col-12 col-lg-12">
              <span
                v-b-tooltip.hover
                title="Necessário preencher campos obrigatórios para salvar."
                class="float-right mt-3"
                v-if="invalid"
              >
                <i class="fas fa-exclamation-circle text-warning"></i>
              </span>
              <button
                :disabled="invalid"
                class="btn btn-primary mx-2 my-2 float-right"
                @click.prevent="returnDataFormInsurer"
              >
                Salvar
              </button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import companyService from "@/services/company";
import commonsService from "@/services/commons";

export default {
  name: "InsurerModal",
  emits: ["returnDataFormInsurer"],
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " ",
      precision: 2,
      masked: false,
    },
    percent: {
      decimal: ".",
      thousands: "",
      prefix: "",
      suffix: "%",
      precision: 2,
      masked: false,
    },
    insurer_status: [],
    rules: {},
    rule: {},
    insurers: {},
    batch_registration: false,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dataFormInsurer: {},
  },
  directives: {
    mask,
  },
  created(){
    this.getInsurerStatus();
    this.getInsurers();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    returnDataFormInsurer() {
      this.dataFormInsurer.batch_registration = this.batch_registration;
      this.$emit("setInsurerData", this.dataFormInsurer);
      this.clearNewInsurerForm();
    },
    clearNewInsurerForm() {
      this.dataFormInsurer.name = "";
      this.dataFormInsurer.approved_limit = "";
      this.dataFormInsurer.available_limit = "";
      this.dataFormInsurer.total_accumulation = "";
      this.dataFormInsurer.current_rate = "";
      this.dataFormInsurer.validity = "";
      this.dataFormInsurer.ccg = 1;
      this.dataFormInsurer.status = "";
      this.dataFormInsurer.modality = 1;
      this.dataFormInsurer.appointment = 1;
      this.dataFormInsurer.index = null;
      this.$refs.showModalInsurer.hide();
    },
    modalInsurerHide() {
      this.clearNewInsurerForm();
    },
    changeIndividual() {
      this.batch_registration = false;
    },
    changeMultiple() {
      this.batch_registration = true;
    },
    getInsurerStatus() {
    this.loading = true;
    companyService
      .getInsurerStatus()
       .then((response) => {
          if (response.data.error) {
            return;
          }
          this.insurer_status = response.data
        })
        .catch(() => {
        this.showError("Não foi possível apagar compania do grupo economico.");
      });
     },
     selectRules(){
      this.selectRulesLimit();
      this.selectRulesDateAndTax();
     },
     selectRulesLimit(){
      if(this.dataFormInsurer.status == 1){
          this.rules = { required: true, min: 3};
      } else {
        this.rules = {};
      }
     },
    selectRulesDateAndTax(){
      if(this.dataFormInsurer.status == 1){
          this.rule = { required: true};
      } else {
        this.rule = {};
      }
     },
    getInsurers() {
      commonsService
        .appointmentLetterInsurers()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.insurers = { ...response.data };
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as seguradoras.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>


.pill-radio {
  border: 1px solid #dee2e6;
  border-radius: 1000px;
  display: inline-flex;
}
.pill-radio .pill-radio-item {
  margin-left: -0.5rem;
}
.pill-radio .pill-radio-item:first-child {
  margin-left: 0;
}
.pill-radio .pill-radio-item input[type=radio] {
  display: none;
}
.pill-radio .pill-radio-item input[type=radio]:checked + label {
  background-color: #a21b93;
  color: #ffffff;
}
.pill-radio .pill-radio-item label {
  cursor: pointer;
  border-radius: 1000px;
  padding-inline: 3rem;
  padding-block: 0.8rem;
  color: #28143c;
  font-weight: 700;
}

</style>
