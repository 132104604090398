<template>
  <div>
    <div>
      <div class="row form">
        <div class="col-12">
          <p>Deseja Iniciar a renovação da <strong>Apólice nº{{ policyRenewSelected.number ?? '---'  }}</strong>?</p>
        </div>
        <div class="col-12">
          <label class="dbo-ui">Tomador</label>
          <p><strong>{{ policyRenewSelected.policy_holder_name ?? '---' }}</strong></p>
        </div>
        <div class="col-12">
          <label class="dbo-ui">Segurado/Beneficiário</label>
          <p><strong>{{ policyRenewSelected.insured_name  ?? '---' }}</strong></p>
        </div>
      </div>
    </div>

    <div class="d-block mt-3">
      <div class="d-flex justify-content-between">
        <button @click="$bvModal.hide('show-modal-create')" class="btn btn-outline-secondary btn-lg px-4 mr-1">
          <i class="fa fa-angle-left mr-2"></i>
          <span class="d-none d-md-inline-block"> Voltar</span>
        </button>
        <button type="button" class="btn btn-primary btn-lg text-uppercase px-4" @click="createRenovation()">
          <b-spinner v-if="loading_create" small></b-spinner> Renovar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import commomsHelper from "@/mixins/commomsHelper";
import policyRenovationService from "@/services/policy-renovation";

export default {
  name: "ManualPolicyRenewal",
  mixins: [commomsHelper],
  props: {
    policyRenewSelected: {
      type: Object,
    }
  },
  data() {
    return {
      loading_create: false,
    };
  },
  methods: {
    createRenovation() {
      this.loading_create = true;

      if (!this.policyRenewSelected) {
        this.showError('Não foi possível iniciar a renovação.');
        return;
      }

      policyRenovationService
        .store(this.policyRenewSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }

          this.redirect('garantee-data', 
          {
            quotationId: response.data.quotation_id,
          },
          {
            isRenewal: true,
          });
        })
        .catch(() => {
          this.showError('Não foi possível renovar a apólice.');
        })
        .finally(() => {
          this.policyRenewSelected = {};
          this.loading_create = false;
          this.$refs.modalCreate.hide();
        });
    },
    redirect(routeName, params = null, query = null) {
      this.$router.push({ name: routeName, params: params, query: query });
    },
  },
};
</script>

<style></style>
