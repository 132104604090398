<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3 d-flex">
          <div class="col-md-3">
            <h1 class="text-white">
              <i class="mdi mdi-handshake"></i>Tomadores
            </h1>
          </div>
          <div class="col-9 d-flex justify-content-end">
            <div>
              <button
                @click="redirect('client-store')"
                class="ml-auto btn btn-outline-white mr-2"
              >
                <i class="fa fa-plus-circle"></i>
                <span class="d-none d-md-inline"> Novo Tomador</span>
              </button>
              <a
                @click="$refs.showModalFilter.show()"
                class="ml-auto btn btn-outline-white no-right-border"
              >
                <i class="fa fa-filter mr-2"></i>
                <span class="d-none d-md-inline">Filtros</span>
              </a>
              <a
                @click="clearFilters"
                class="ml-auto btn btn-outline-white ml-1 no-left-border mr-1"
              >
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>

      <div
        class="gray-table table-responsive"
        style="margin-bottom: 50px; width: 100%"
      >
        <table class="custom-table custom-table-quotation no-wrap">
          <thead>
            <tr>
              <th>NOME DO GRUPO</th>
              <th>CNPJ</th>
              <th>RAZÃO SOCIAL</th>
              <th>QUANTIDADE DE APÓLICES</th>
              <th >ÚLTIMA EMISSÃO</th>
              <th class="text-center custom-actions-th">AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.group_name }}</td>
              <td>{{ formatCnpj(item.cnpj) }}</td>
              <td>{{ item.company_name }}</td>
              <td>{{ item.policies_quantity }}</td>
              <td>
                <span v-if="item.last_issue && item.last_issue.created_at">
                  {{ formatDateLocal(item.last_issue.created_at) }}
                </span>
                <span v-else> - - - - - </span>
              </td>
              <td class="actions text-right">
                <button
                  class="btn btn-action-gray"
                  @click="redirect('show-client', { economicGroupID: item.id })"
                  v-b-tooltip.hover
                  title="Visualizar"
                >
                  <i class="fas fa-eye"></i>
                </button>
                <button
                  class="btn btn-action-gray"
                  @click="
                    redirect('update-client', { economicGroupID: item.id })
                  "
                  v-b-tooltip.hover
                  title="Editar"
                >
                  <i class="fas fa-pen"></i>
                </button>
                <button
                  class="btn btn-action-gray"
                  @click="showModalDelete(item)"
                  v-b-tooltip.hover
                  title="Excluir"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Paginação" class="md-3" style="padding-left:2.5%;">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @page-click="pageChanged"
          ></b-pagination>
        </nav>
      </div>
    </div>

    <div class="modal">
      <b-modal
        id="show-modal-delete"
        ref="showModalDelete"
        class="mt-4"
        size="sm"
        centered
        hide-footer
      >
        <br />
        <template #modal-title>
          Deseja deletar esse grupo ecônomico ?
        </template>
        <div class="mb-5 pb-2">
          <h6 class="dbo-ui">
            Deseja deletar o grupo economico
            <p>
              "
              {{ economic_group_data ? economic_group_data.group_name : "" }}
              "
            </p>
            detentor do cnpj
            <p>
              {{ economic_group_data ? economic_group_data.cnpj : "" }}
            </p>
          </h6>
        </div>
        <div>
          <a
            @click="$bvModal.hide('show-modal-delete')"
            class="btn btn-outline-secondary"
            ><i class="fa fa-angle-left"></i> Voltar</a
          >
          <a @click="deleteEconomicGroup" class="btn btn-md btn-danger ml-2"
            ><i class="fas fa-times"></i> Confirmar</a
          >
        </div>
      </b-modal>
    </div>

    <div class="modal">
      <b-modal
        id="show-modal-filter"
        ref="showModalFilter"
        size="md"
        class="mt-4"
        hide-footer
      >
        <template #modal-title>
          <h5 class="modal-title">Filtrar Tomador</h5>
        </template>
        <div>
          <label class="dbo-ui" for="name">Digite o nome do grupo</label>
          <b-input v-model="group_name" placeholder="Nome do grupo"></b-input>

          <button class="btn btn-primary my-2 col-md-12" @click="applyFilter()">
            Buscar
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>


<script>
import companyService from "@/services/company";
import dateFormatter from "@/mixins/dateFormatter";
import commomsHelper from "@/mixins/commomsHelper";

export default {
  name: "list-client",
  mixins: [dateFormatter, commomsHelper],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    items: [],
    filter: null,
    economic_group_data: null,
    loading: false,
    group_name: "",
    totalRows: 0,
  }),
  created() {
    this.getEconomicGroups();
  },
  methods: {
    getEconomicGroups() {
      this.loading = true;
      companyService
        .getEconomicGroups({
          group_name: this.group_name,
          page: this.currentPage,
          per_page: this.perPage,
        })
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.items = response.data.data;
          this.totalRows = response.data.total;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os grupos econômicos.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pageChanged(bvEvent, pageNumber) {
      this.currentPage = pageNumber;
      this.getEconomicGroups();
    },
    applyFilter() {
      this.$refs.showModalFilter.hide();
      this.getEconomicGroups();
    },
    clearFilters() {
      this.group_name = "";
      this.currentPage = 1;
      this.getEconomicGroups();
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    showModalDelete(economic_group_data) {
      this.economic_group_data = economic_group_data;
      this.$refs.showModalDelete.show();
    },
    deleteEconomicGroup() {
      this.loading = true;
      this.$refs.showModalDelete.hide();
      companyService
        .deleteEconomicGroup(this.economic_group_data.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível apagar os grupos economicos.");
        })
        .finally(() => {
          this.getEconomicGroups();
        });
    },
  },
};
</script>

<style>
</style>
