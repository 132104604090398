<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3 d-flex">
          <div class="col-md-3">
            <h1 class="text-white">
              <i class="mdi mdi-cash-percent"></i> Comissão
            </h1>
          </div>
          <div class="col-9 d-flex justify-content-end">
            <button
              type="button"
              class="button btn btn-outline-white"
              v-b-toggle.collapse-filter
            >
              <span class="mr-2">Filtros</span>
              <i class="fa fa-angle-up status-icon"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Filtros -->
      <div class="row justify-content-end mb-3">
        <div class="col-12 col-lg-5">
          <b-collapse id="collapse-filter" class="w-100">
            <b-input-group>
              <b-form-input
                v-model="filter"
                placeholder="Digite o que deseja buscar"
              ></b-form-input>
              <b-input-group-prepend>
                <span
                  class="input-group-text cursor-pointer"
                  @click="applyFilter"
                  v-b-tooltip.hover
                  title="Buscar"
                >
                  <i class="fa fa-search"></i>
                </span>
              </b-input-group-prepend>
              <b-input-group-append>
                <span
                  class="input-group-text cursor-pointer"
                  @click="clearFilter"
                  v-b-tooltip.hover
                  title="Limpar"
                >
                  <i class="fa fa-times"></i>
                </span>
              </b-input-group-append>
            </b-input-group>
          </b-collapse>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div class="gray-table table-responsive" style="margin-bottom: 50px; width: 100%">
        <table class="custom-table custom-table-quotation no-wrap text-center">
          <thead>
            <tr>
              <th class="text-center">ID</th>
              <th class="text-center">Seguradora</th>
              <th class="text-center">Percentual da Comissão</th>
              <th class="text-center custom-actions-th">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in commissions" :key="index">
              <td class="text-center">{{ item.id }}</td>
              <td class="text-center">
                <img v-if="item.insurer_icon" :src="item.insurer_icon" class="insurance-logo" />
                <span v-else>{{ item.insurer }}</span>
              </td>
              <td class="text-center">{{ item.percentage }}%</td>
              <td class="actions text-center">
                <button
                  class="btn btn-action-gray"
                  @click="openModal(item)"
                  v-b-tooltip.hover
                  title="Editar"
                >
                  <i class="fas fa-pen"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Paginação" class="md-3 text-center" style="padding-left: 2.5%">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="pageChanged"
            aria-controls="my-table"
          ></b-pagination>
        </nav>
      </div>

      <commission-modal ref="commissionModal" @save="saveCommission" />
    </div>
  </div>
</template>

<script>
import { CommissionModal } from "@/components/Commission";
import commissionService from "@/services/commission";
export default {
  name: "ListCommission",
  components: { CommissionModal },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      commissions: [],
      filter: null,
      loading: false,
      totalRows: 0,
      alertMessage: "",
    };
  },
  created() {
    this.getCommissions();
  },
  methods: {
    openModal(item) {
      this.$refs.commissionModal.open(item);
    },
    saveCommission(data) {
      this.store(data);
      this.getCommissions();
    },
    pageChanged(page) {
      this.currentPage = page;
      this.getCommissions();
    },
    clearFilter() {
      this.filter = "";
      this.getCommissions();
    },
    applyFilter() {
      this.getCommissions();
    },
    getCommissions() {
      this.loading = true;
      commissionService
        .get({
          page: this.currentPage,
          per_page: this.perPage,
          filter: this.filter,
        })
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.commissions = response.data.data;
          this.totalRows = response.data.total;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de seguradoras.");
        }).finally(() => {
          this.loading = false;
        });
    },
    store(data) {
      commissionService
        .update(data.id, data)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de seguradoras.");
        }).finally(() => {
          this.getCommissions();
        });
    },
  }
};
</script>

<style scoped>
td.text-center {
  vertical-align: middle;
}

td.text-center img,
td.text-center span {
  display: block;
  margin: auto;
  text-align: center;
}

</style>
