<template>
  <div class="page-container-quotation">
    <div class="page-content">
      <div class="page-header">
        <div
          v-if="loading_page"
          class="overlay d-flex align-items-center justify-content-center"
        >
          <div>
            <div class="d-flex justify-content-center mb-2">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center pb-4">
          <div class="col-6">
            <h1 class="text-white">
              <i class="mdi mdi-file-table-box-multiple-outline"></i> Endossos
            </h1>
          </div>
          <div
            class="col-6 d-flex justify-content-end"
            style="padding-right: 0.23%"
          >
            <button
              class="btn btn-outline-white"
              v-b-modal.modal-filter
              style="border-radius: 5px 0px 0px 5px"
            >
              <i class="fas fa-filter"></i>
              <span class="d-none d-md-inline-block ml-2">Filtros</span>
            </button>
            <button
              class="btn btn-outline-white"
              @click="cleanFilter()"
              style="border-radius: 0px 5px 5px 0px"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <div class="page-body-quotation h-100 overflow-auto">
        <div class="card-body p-0">
          <div class="row flex-nowrap">
            <div
              class="col-12 col-sm-6 col-lg-3 ml-1 px-3"
              v-for="(column, index) in columnKanban()"
              :key="index"
            >
              <div class="card shadow mb-3 border-card">
                <div
                  :class="cardColors[column]"
                  class="kanban-list border-kanban-title card-header border-bottom px-3 text-uppercase text-center font-weight-bold"
                >
                  <div class="kanban-list-header-quotation">
                    <div class="kanban-list-title-quotation">
                      {{
                        getEnumKeyByEnumValue(
                          translateStatusQuotationTypes,
                          column
                        )
                      }}
                    </div>
                    <div class="float-right kanban-list-total-cards mt-2">
                      <i class="fa fa-copy"></i>
                      <span class="ml-1">{{ cardCount(column) }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="card-body fade-in kanban-list-cards-quotation"
                  :class="{ 'scroll-disabled': loadingColumns[column] }"
                  @scroll="onScroll(column)"
                  :ref="`kanbanListCards${column}`"
                  :data-column-id="column"
                >
                  <draggable
                    :list="items[column]"
                    :group="{ name: 'kanban', pull: true, put: true }"
                    class="data-list-limit"
                    :disabled="true"
                  >
                    <div
                      v-for="(endorsement, index) in items[column]"
                      :key="endorsement.id || index"
                      class="kanban-card"
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-link text-secondary position-absolute"
                        style="top: 0.5rem; right: 0.5rem; z-index: 10"
                        @click="showModal(endorsement)"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </button>
                      <div class="kanban-card-content-quotation">
                        <div
                          class="pointer mr-1 text-wrap"
                          style="flex: 1; min-width: 250px"
                        >
                          <strong
                            @click="
                              $can('policy-show')
                                ? redirect('details-policy', {
                                    policyId: endorsement.policy_id,
                                  })
                                : null
                            "
                            >{{ endorsement.policy_holder_name }}</strong
                          >
                          <br />
                          <span
                            ><i class="far fa-user mr-2"></i
                            >{{ endorsement.insured_name }}</span
                          >
                          <br />
                          <span
                            ><i class="far fa-file-alt mr-2"></i
                            >{{ endorsement.policy_number }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </draggable>
                  <div
                    v-if="loadingColumns[column]"
                    class="loading-quotation-column"
                  >
                    <b-spinner small label="Carregando..."></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de status de endosso -->
    <b-modal v-model="showModalStatus" id="modal-endosso-status" size="lg">
      <template #modal-title>
        <div>
          <div>
            <h5 class="modal-title" id="modal-cotacao-status-label">
              Alterar Endosso
            </h5>
            <p class="text-muted mb-0">Revise e altere o status do endosso</p>
          </div>
        </div>
      </template>
      <div v-if="endorsementSelected" class="modal-lg">
        <div class="p-3 pb-0">
          <div>
            <div class="row form">
              <div class="col-12">
                <label class="dbo-ui">Tomador</label>
                <p>
                  {{ formatCnpj(endorsementSelected.policy_holder_document) }} -
                  <strong>{{ endorsementSelected.policy_holder_name }}</strong>
                </p>
              </div>
              <div class="col-sm-6">
                <label class="dbo-ui">Tipo de Endosso</label>
                <p>
                  {{
                    endorsementSelected.endorsement_type
                      ? endorsementSelected.endorsement_type.translate
                      : "---"
                  }}
                </p>
              </div>
              <div class="col-sm-6">
                <label class="dbo-ui">Usuário</label>
                <p>
                  {{
                    endorsementSelected.user
                      ? endorsementSelected.user.name
                      : "---"
                  }}
                </p>
              </div>
              <div class="col-12">
                <label class="dbo-ui">Descrição</label>
                <p>{{ endorsementSelected.description }}</p>
              </div>

              <div class="col-12 col-lg-4" v-if="showValueField">
                <label class="dbo-ui">Novo Valor</label>
                <p>
                  {{ formatCurrency(endorsementSelected.new_coverage_value) }}
                </p>
              </div>
              <div
                class="col-12 col-lg-4"
                v-if="endorsementSelected.new_validity_start"
              >
                <label class="dbo-ui">Inicio da nova IS</label>
                <p>
                  {{ formatDateLocal(endorsementSelected.new_validity_start) }}
                </p>
              </div>
              <div class="col-12 col-lg-4" v-if="showDateField">
                <label class="dbo-ui">Nova data de vencimento</label>
                <p>
                  {{ formatDateLocal(endorsementSelected.new_expiration_date) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12 pb-4" v-if="isMaster()">
        <label class="dbo-ui" for="">Status</label>
        <select class="custom-select" v-model="dataForm.status">
          <option value="null" selected>Selecione...</option>
          <option
            v-for="(status, index) in options"
            :key="index"
            :value="status.value"
          >
            {{ status.text }}
          </option>
        </select>
      </div>
      <template #modal-footer>
        <button
          @click="showModalDestroy()"
          type="button"
          class="btn btn-danger mr-auto"
        >
          <b-spinner v-if="loading_delete" small></b-spinner> Excluir Endosso
        </button>

        <button
          :disabled="disabledButtonChange"
          @click="changeStatusEndorsement()"
          type="button"
          class="btn btn-primary"
        >
          <b-spinner v-if="loading" small></b-spinner> Alterar
        </button>
      </template>
    </b-modal>

    <b-modal id="modal-filter" ref="modalFilter" size="lg">
      <template #modal-title>
        <div>
          <h5 class="modal-title" id="modal-cotacao-status-label">
            Filtro de Endosso
          </h5>
        </div>
      </template>
      <div class="modal-lg">
        <div class="p-3 pb-0">
          <div class="row form">
            <div class="col-md-6">
              <label class="dbo-ui">Número FINN</label>
              <b-form-input v-model="filters.policy_number"></b-form-input>
            </div>
            <div class="col-md-6">
              <label class="dbo-ui">Número da Apolice</label>
              <b-form-input v-model="filters.susep"></b-form-input>
            </div>
            <div class="col-12">
              <label class="dbo-ui">Tomador</label>
              <b-form-input v-model="filters.policy_holder"></b-form-input>
            </div>
            <div class="col-12">
              <label class="dbo-ui">Tipo de Endosso</label>
              <div class="input-group">
                <b-form-select v-model="filters.endorsement_type" class="mb-3">
                  <b-form-select-option value=""
                    >Sem Filtro</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(value, index) in translateEndorsementTypes"
                    :value="index"
                    :key="index"
                  >
                    {{ value }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
            <div class="col-12">
              <label class="dbo-ui">Seguradora</label>
              <div class="input-group">
                <b-form-select v-model="filters.insurer" class="mb-3">
                  <b-form-select-option value=""
                    >Sem Filtro</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(value, index) in list_insurers"
                    :value="index"
                    :key="index"
                  >
                    {{ value }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button @click="filterEndorsement()" variant="outline-primary"
          >Filtrar <i class="fa fa-search"></i
        ></b-button>
      </template>
    </b-modal>

    <!-- Modal de exclusão de endosso -->
    <div class="modal">
      <b-modal
        id="show-modal"
        ref="showModal"
        size="md"
        class="mt-4"
        hide-footer
        centered
      >
        <template #modal-title>Excluir Endosso</template>

        <div class="card card-termo-uso mt-1 mb-2">
          <p v-html="deletion_msg"></p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              @click="destroyEndorsement()"
              class="btn btn-primary btn-lg text-uppercase px-4"
              data-toggle="modal"
              data-target="#show-modal"
            >
              Excluir<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import policyService from "@/services/policy";
import dateFormatter from "@/mixins/dateFormatter";
import commomsHelper from "@/mixins/commomsHelper";
import numericFormatter from "@/mixins/numericFormatter";
import authenticationService from "@/services/authentication";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import endorsementTypes from "@/enums/endorsementTypes";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import translateEndorsementTypes from "@/enums/translate/endorsementTypeText";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import draggable from "vuedraggable";

export default {
  name: "list-endorsement",
  components: { draggable },
  mixins: [dateFormatter, commomsHelper, numericFormatter],
  data: () => ({
    cardColors: {
      11: "kanban-list-status-yellow",
      12: "kanban-list-status-green",
      13: "kanban-list-status-orange",
      14: "kanban-list-status-red",
    },
    currentPage: { 11: 1, 12: 1, 13: 1, 14: 1 },
    dataForm: { status: null },
    deletion_msg: "",
    endorsementSelected: {},
    endorsementTypes: endorsementTypes,
    filters: {
      policy_number: "",
      policy_holder: "",
      susep: "",
      endorsement_type: "",
      insurer: "",
    },
    items: { 11: [], 12: [], 13: [], 14: [] },
    list_insurers: [],
    loading: false,
    loadingColumns: { 11: false, 12: false, 13: false, 14: false },
    loading_delete: false,
    loading_page: false,
    maxPages: { 11: null, 12: null, 13: null, 14: null },
    options: [],
    perPage: 10,
    showModalStatus: false,
    statusQuotationTypes: statusQuotationTypes,
    translateInsuranceTypes: translateInsuranceTypes,
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    translateEndorsementTypes: translateEndorsementTypes,
  }),
  created() {
    this.loadAllColumns();
    this.getStatusKanban();
    this.getInsurers();
  },
  computed: {
    rows() {
      return this.items.length;
    },
    disabledButtonChange() {
      return this.dataForm.status ? false : true;
    },
    showValueField() {
      return (
        this.endorsementSelected.type == this.endorsementTypes.INCREASE_VALUE ||
        this.endorsementSelected.type ==
          this.endorsementTypes.INCREASE_VALUE_AND_EXPIRATION ||
        this.endorsementSelected.type == this.endorsementTypes.DECREASE_VALUE
      );
    },
    showDateField() {
      return (
        this.endorsementSelected.type ==
          this.endorsementTypes.INCREASE_EXPIRATION ||
        this.endorsementSelected.type ==
          this.endorsementTypes.INCREASE_VALUE_AND_EXPIRATION ||
        this.endorsementSelected.type ==
          this.endorsementTypes.DECREASE_EXPIRATION
      );
    },
  },
  methods: {
    getInsurers() {
      policyService
        .getInsurers()
        .then((response) => {
          this.list_insurers = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de seguradoras.");
        });
    },
    loadAllColumns() {
      [11, 12, 13, 14].forEach((column) => {
        this.getEndorsements(column);
      });
    },
    getEndorsements(column) {
      if (this.loadingColumns[column]) return;
      this.loadingColumns[column] = true;

      policyService
        .getEndorsements({
          status_id: column,
          page: this.currentPage[column],
          ...this.filters,
        })
        .then((response) => {
          const data = response.data.data || [];
          this.items[column] = [...this.items[column], ...data];
          this.maxPages[column] = response.data.last_page;

          if (data.length > 0) {
            this.currentPage[column] += 1;
          }
        })
        .catch(() => {
          this.alertMessage = "Erro ao carregar dados.";
        })
        .finally(() => {
          this.loadingColumns[column] = false;
        });
    },
    formatListQuotation(data) {
      Object.keys(statusQuotationTypes).forEach((element) => {
        this.$set(
          this.items,
          element,
          data.filter((elementData) => {
            return elementData.status_id == statusQuotationTypes[element];
          })
        );
      });
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    showModalDestroy() {
      if (this.endorsementSelected) {
        this.showModalStatus = false;
        this.deletion_msg =
          "Deseja realmente excluir o endosso de " +
          (this.endorsementSelected.endorsement_type
            ? "<strong>" +
              this.endorsementSelected.endorsement_type.translate +
              "</strong> de "
            : "") +
          "<strong>" +
          this.endorsementSelected.policy_holder_name +
          "</strong>?";
        this.$refs.showModal.show();
      }
    },
    showModal(element) {
      this.endorsementSelected = element;
      this.showModalStatus = true;
    },
    destroyEndorsement() {
      this.loading_delete = true;
      this.$refs.showModal.hide();
      policyService
        .destroyEndorsement(this.endorsementSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.loadAllColumns();
        })
        .catch(() => {
          this.showError("Não foi possível remover a cotação.");
        })
        .finally(() => {
          this.endorsementSelected = {};
          this.loading_delete = false;
        });
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    columnKanban() {
      return [11, 12, 13, 14];
    },
    cardCount(column) {
      return this.items[column] ? this.items[column].length : 0;
    },
    cleanFilter() {
      this.filters.policy_number = "";
      this.filters.policy_holder = "";
      this.filters.susep = "";
      this.filters.endorsement_type = "";
      this.filters.insurer = "";
      this.resetColumns();
    },
    filterEndorsement() {
      this.resetColumns();
      const filters = {
        policy_number: this.filters.policy_number || null,
        policy_holder: this.filters.policy_holder || null,
        susep: this.filters.susep || null,
        endorsement_type: this.filters.endorsement_type || null,
        insurer: this.filters.insurer || null,
      };
      Object.keys(this.items).forEach((column) => {
        this.getEndorsements(column, filters);
      });
      this.$refs.modalFilter.hide();
    },
    getStatusKanban() {
      this.listStatusKanbanEndorsement().forEach((element) => {
        this.options.push({
          value: element,
          text: this.getEnumKeyByEnumValue(
            this.translateStatusQuotationTypes,
            element
          ),
        });
      });
    },
    changeStatusEndorsement() {
      this.loading_page = true;
      this.resetAlert();
      policyService
        .updateStatusEndorsement(this.endorsementSelected.id, this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.loadAllColumns();
        })
        .catch(() => {
          this.showError("Não foi possível mudar o status do endosso.");
        })
        .finally(() => {
          this.showModalStatus = false;
          this.dataForm.status = null;
          this.loading_page = false;
          this.resetColumns();
        });
    },
    showModalDelete(element) {
      this.endorsementSelected = element;
      this.showModalStatus = true;
      this.showModalDestroy();
    },
    onScroll(column) {
      const containers = this.$refs[`kanbanListCards${column}`];
      const container = Array.isArray(containers) ? containers[0] : containers;

      if (
        container &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 10 &&
        !this.loadingColumns[column] &&
        (this.maxPages[column] === null ||
          this.currentPage[column] <= this.maxPages[column])
      ) {
        this.getEndorsements(column);
      }
    },
    resetColumns() {
      this.currentPage = { 11: 1, 12: 1, 13: 1, 14: 1 };
      this.maxPages = { 11: null, 12: null, 13: null, 14: null };
      this.items = { 11: [], 12: [], 13: [], 14: [] };
      this.loadingColumns = { 11: false, 12: false, 13: false, 14: false };
      this.loadAllColumns();
    },
  },
};
</script>

<style></style>
