<template>
  <div class="scrollable-economic-group-table" style="max-height: 330px">
    <table class="table pretty-title-gray sticky-header" style="background-color: #f8f8f8">
      <thead>
        <tr>
          <th class="table-pretty dbo-ui rounded-top centered-header">CNPJ</th>
          <th class="table-pretty dbo-ui rounded-top centered-header">Nome</th>
          <th class="table-pretty dbo-ui rounded-top centered-header">Tipo</th>
          <th class="table-pretty dbo-ui rounded-top centered-header">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index" class="cursor-hover-row">
          <td>{{ item.cnpj }}</td>
          <td class="text-truncate">{{ truncateText(item.company_name) }}</td>
          <td style="">
            <span v-if="item.type == 1">Coligada</span>
            <span v-else-if="item.type == 2">Filial</span>
            <span v-else-if="item.type == 3">Controlada</span>
            <span v-else-if="item.type == 4">Controladora</span>
          </td>
          <td>
            <a @click="$emit('deleteCompany', item)" class="text-secondary mr-2">
              <i class="fas fa-times"></i>
            </a>
            <a @click="$emit('showCompany', item)" class="text-secondary">
              <i class="fas fa-pen"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    truncateText(text, maxChar = 20) {
      return text.length > maxChar ? `${text.substring(0, maxChar)}...` : text;
    },
  },
};
</script>

<style scoped>
</style>
