<template>
  <nav id="sidebar">
    <div class="sidebar-container mt-5">
      <ul class="list-unstyled d-flex flex-column">
        <li class="mt-side">
          <a @click="redirect('dashboard')" class="py-3 px-3 pointer">
            <i class="mdi sidebar-item-icon mdi-chart-arc"></i>
            <span class="sidebar-item-title mt-2">Dashboard</span>
          </a>
        </li>
        <!-- Cotações -->
        <li v-if="$can('quotation-list')">
          <a
            @click="toggleQuotations"
            :class="[
              'py-3 px-3 pointer',
              isQuotationsActive ? 'purple-border' : '',
            ]"
          >
            <i class="mdi sidebar-item-icon mdi-buffer"></i>
            <span class="sidebar-item-title">
              Cotações
              <i
                :class="[
                  'mdi',
                  isQuotationsActive ? 'mdi-chevron-down' : 'mdi-chevron-up',
                  'small-icon',
                ]"
              ></i>
            </span>
          </a>
        </li>
        <li v-if="isQuotationsActive">
          <a
            @click="redirect('list-quotation')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-view-list sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-1 sidebar-text-color"
              >Listagem de cotações</span
            >
          </a>
        </li>
        <li v-if="isQuotationsActive">
          <a
            @click="redirect('kanban-quotation')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-file-table-box-multiple-outline sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-1 sidebar-text-color"
              >Painel de cotações</span
            >
          </a>
        </li>
        <!-- Apólices -->
        <li v-if="$can('policy-list')">
          <a
            @click="togglePolicies"
            :class="[
              'py-3 px-3 pointer',
              isPoliciesActive ? 'purple-border' : '',
            ]"
          >
            <i class="mdi sidebar-item-icon mdi-folder-open"></i>
            <span class="sidebar-item-title mt-1"
              >Apólices
              <i
                :class="[
                  'mdi',
                  isPoliciesActive ? 'mdi-chevron-down' : 'mdi-chevron-up',
                  'small-icon',
                ]"
              ></i
            ></span>
          </a>
        </li>
        <li v-if="isPoliciesActive">
          <a
            @click="redirect('list-policies')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-checkbox-multiple-marked sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Apólices emitidas</span
            >
          </a>
        </li>
        <li v-if="$can('claim-list') && isPoliciesActive">
          <a
            @click="redirect('list-claims')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-shield-alert sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Sinistros</span
            >
          </a>
        </li>
        <li v-if="$can('endorsement-list') && isPoliciesActive">
          <a
            @click="redirect('list-endorsement')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-thumb-up sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Endossos</span
            >
          </a>
        </li>
        <li v-if="$can('policy-list') && isPoliciesActive">
          <a
            @click="redirect('kanban-renewal')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-calendar-clock sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Renovações</span
            >
          </a>
        </li>


        <li v-if="$can('policy-list') && isPoliciesActive">
          <a
            @click="redirect('list-billets')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-text-box sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Boletos</span
            >
          </a>
        </li>

        <!-- Administração -->
        <li @click="toggleAdministration" v-if="$can('endorsement-list')">
          <a :class="['py-3 px-3 pointer', isAdmActive ? 'purple-border' : '']">
            <i class="mdi sidebar-item-icon mdi-cog"></i>
            <span class="sidebar-item-title mt-1"
              >administração
              <i
                :class="[
                  'mdi',
                  isAdmActive ? 'mdi-chevron-down' : 'mdi-chevron-up',
                  'small-icon',
                ]"
              ></i
            ></span>
          </a>
        </li>
        <li v-if="$can('certificate-list') && isAdmActive">
          <a
            @click="redirect('certificate-list')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-file-document-multiple sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Certidões</span
            >
          </a>
        </li>
        <li v-if="$can('docusign-store') && isAdmActive">
          <a
            @click="redirect('appointment-letter')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-email-open-multiple sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Carta de Nomeação</span
            >
          </a>
        </li>
        <li v-if="$can('process-list') && isAdmActive">
          <a
            @click="redirect('list-jurimetry')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-gavel sidebar-text-color pr-1"
              style="font-size: 1.1em"
            ></i>
            <span class="sidebar-item-title mt-1 sidebar-text-color"
              >Apoio Jurídico</span
            >
          </a>
        </li>
         <li v-if="$can('user-list') && isAdmActive && isMaster()">
          <a
            @click="redirect('list-client')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-handshake sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Area do Tomador</span
            >
          </a>
        </li>
        <li v-if="$can('integration-list') && isAdmActive && isCommercial()">
          <a
            @click="redirect('list-integrations')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-link-variant"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Integrações</span
            >
          </a>
        </li>
        <li v-if="$can('user-list') && isAdmActive">
          <a
            @click="redirect('list-users')"
            class="py-3 px-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-account-multiple sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Usuários</span
            >
          </a>
        </li>
         <li v-if="isMaster() && isAdmActive">
          <a
            @click="redirect('list-commission')"
            class="py-3 pointer color-transparent"
          >
            <i
              class="mdi sidebar-item-icon mdi-currency-usd sidebar-text-color"
            ></i>
            <span class="sidebar-item-title mt-2 sidebar-text-color"
              >Comissão</span
            >
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import authenticationService from "@/services/authentication";
export default {
  name: "SideBar",
  data() {
    return {
      isQuotationsActive: true,
      isPoliciesActive: true,
      isAdmActive: false,
    };
  },
  methods: {
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    toggleQuotations() {
      this.isQuotationsActive = !this.isQuotationsActive;
    },
    togglePolicies() {
      this.isPoliciesActive = !this.isPoliciesActive;
    },
    toggleAdministration() {
      this.isAdmActive = !this.isAdmActive;
    },
    isMaster(){
      return authenticationService.isMaster()
    },
    isCommercial(){
      return authenticationService.isCommercial()
    },
  },
};
</script>
<style>
</style>
