<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-3">
          <h1 class="text-white" v-if="is_edit">
            <i class="mdi mdi-handshake"></i> Editar Tomador
          </h1>
          <h1 class="text-white" v-else>
            <i class="mdi mdi-handshake"></i> Novo Tomador
          </h1>
          <span @click="redirect()" class="btn btn-outline-white ml-auto"><i class="fa fa-arrow-left mr-1"></i>
            <span class="d-none d-md-inline">Voltar</span></span>
        </div>
      </div>
      <div v-if="alertMessage" class="alert-custom alert-danger-custom">
        {{ alertMessage }}
      </div>
      <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <div class="page-body">
        <div class="card">
          <div class="card-body p-3 p-lg-5">
            <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
              <b-form @submit.stop.prevent="handleSubmit(saveEconomicGroup(true))" class="form" autocomplete="off">
                <div class="col-12 mb-3">
                  <h5>
                    <div class="form-subheader">
                      <span>Dados do Tomador</span>
                    </div>
                  </h5>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="col-md-12 mt-2">
                      <validation-provider name="Nome do grupo" rules="required" v-slot="validationContext" vid="nome">
                        <label class="dbo-ui">GRUPO ECONÔMICO</label>
                        <b-form-input v-model="dataFormCompany.group_name" placeholder="Digite o nome do grupo"
                          :state="getValidationState(validationContext)" @blur="checkAndSave">
                        </b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-12">
                      <validation-provider name="cnpj do Tomador" rules="required|min:14" v-slot="validationContext"
                        @blur="checkAndSave" vid="cnpj">
                        <label class="dbo-ui">CNPJ</label>
                        <b-form-input @change="onChangeCompanyCnpj" v-model="dataFormCompany.cnpj"
                          v-mask="'##.###.###/####-##'" placeholder="Digite o CNPJ da empresa"
                          :state="getValidationState(validationContext)">
                        </b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-12 mt-2">
                      <validation-provider name="razão social" rules="required" @blur="checkAndSave"
                        v-slot="validationContext" vid="cnpj">
                        <label class="dbo-ui">Razão social</label>
                        <b-form-input v-model="dataFormCompany.company_name" placeholder="Digite o nome da empresa"
                          :state="getValidationState(validationContext)">
                        </b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-12 mb-3" style="background-color: #fff">
                      <label class="dbo-ui">Comercial Responsável</label>
                      <b-dropdown id="dropdown-comercial" class="w-100" toggle-class="w-100 text-left"
                        menu-class="w-100 dropdown-menu-scrollable" style="background-color: #fff"
                        :disabled="isFormDisabled"
                        :style="{ backgroundColor: isFormDisabled ? '#e9ecef' : '#fff', cursor: isFormDisabled ? 'not-allowed' : 'pointer' }"
                        :title="isFormDisabled ? 'Preencha os campos de Grupo Econômico, CNPJ e Comercial Responsável' : ''">
                        <template #button-content>
                          <span>
                            {{
                              selectedCommercial
                                ? truncateText(selectedCommercial.text, 52)
                                : "Digite o nome do comercial responsável..."
                            }}
                          </span>
                        </template>

                        <div class="dropdown-fixed-header">
                          <b-form-input v-model="searchQuery" class="mb-2 w-100 no-padding-input"></b-form-input>
                        </div>

                        <div class="dropdown-scrollable-items">
                          <b-dropdown-item v-for="option in filteredOptions" :key="option.value"
                            @click="selectCommercial(option)" :class="{
                              'selected-option':
                                selectedCommercial &&
                                selectedCommercial.value === option.value,
                            }">
                            {{ option.text }}
                            <i v-if="
                              selectedCommercial &&
                              selectedCommercial.value === option.value
                            " class="fa fa-check check-icon"></i>
                          </b-dropdown-item>

                          <b-dropdown-text v-if="filteredOptions.length === 0" class="text-muted text-white p-2">
                            Nenhum resultado encontrado.
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </div>
                    <div class="col-md-12 mb-3 pt-2">
                      <h5>
                        <div class="form-subheader">
                          <span>Dados bancários</span>
                        </div>
                      </h5>
                      <div class="row">
                        <div class="col-md-6">
                          <validation-provider name="banco" v-slot="validationContext" vid="bank">
                            <label class="dbo-ui">Banco</label>
                            <b-form-input v-model="dataFormCompany.bank" placeholder="Digite o Banco"
                              :disabled="isFormDisabled" :title="isFormDisabled
                                  ? 'Preencha os campos de Dados do Tomador'
                                  : ''
                                " :class="{ 'disabled-field': isFormDisabled }">
                            </b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                          </validation-provider>
                        </div>

                        <div class="col-md-6">
                          <validation-provider name="agencia" v-slot="validationContext" vid="agency">
                            <label class="dbo-ui">Agência</label>
                            <b-form-input v-model="dataFormCompany.agency" placeholder="Digite a agência"
                              :disabled="isFormDisabled" :title="isFormDisabled
                                  ? 'Preencha os campos de Dados do Tomador'
                                  : ''
                                " :class="{ 'disabled-field': isFormDisabled }">
                            </b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                          </validation-provider>
                        </div>

                        <div class="col-md-6">
                          <validation-provider name="conta" v-slot="validationContext" vid="current_account">
                            <label class="dbo-ui">Conta corrente</label>
                            <b-form-input v-model="dataFormCompany.current_account"
                              placeholder="Digite a conta corrente" :disabled="isFormDisabled" :title="isFormDisabled
                                  ? 'Preencha os campos de Dados do Tomador'
                                  : ''
                                " :class="{ 'disabled-field': isFormDisabled }">
                            </b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                          </validation-provider>
                        </div>

                        <div class="col-md-6">
                          <validation-provider name="pix" v-slot="validationContext" vid="pix_key">
                            <label class="dbo-ui">Chave pix</label>
                            <b-form-input v-model="dataFormCompany.pix_key" placeholder="Digite a chave pix"
                              :disabled="isFormDisabled" :title="isFormDisabled
                                  ? 'Preencha os campos de Dados do Tomador'
                                  : ''
                                " :class="{ 'disabled-field': isFormDisabled }">
                            </b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                          </validation-provider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="border-left col-lg-6 overflow-hidden">
                    <div class="row ml-2">
                      <div class="col-md-6">
                        <label class="dbo-ui">GRUPO ECONÔMICO</label>
                        <div class="d-flex">
                          <b-form-radio v-model="selected_option" value="true" @change="updateSelectedOption(true)"
                            :disabled="isFormDisabled" :title="isFormDisabled
                                ? 'Preencha os campos de Dados do Tomador'
                                : ''
                              " :class="{ 'disabled-field': isFormDisabled }">Sim</b-form-radio>
                          <b-form-radio v-model="selected_option" value="false" class="ml-3"
                            @change="updateSelectedOption(false)" :disabled="isFormDisabled" :title="isFormDisabled
                                ? 'Preencha os campos de Dados do Tomador'
                                : ''
                              " :class="{ 'disabled-field': isFormDisabled }">Não</b-form-radio>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <label class="dbo-ui">Fatura única</label>
                        <div class="d-flex">
                          <b-form-radio v-model="single_invoice" value="1" :disabled="isFormDisabled" :title="isFormDisabled
                              ? 'Preencha os campos de Dados do Tomador'
                              : ''
                            " :class="{ 'disabled-field': isFormDisabled }">Sim</b-form-radio>
                          <b-form-radio v-model="single_invoice" value="0" class="ml-3" :disabled="isFormDisabled"
                            :title="isFormDisabled
                                ? 'Preencha os campos de Dados do Tomador'
                                : ''
                              " :class="{ 'disabled-field': isFormDisabled }">Não</b-form-radio>
                        </div>
                      </div>

                      <div v-if="selected_option" class="col-md-12 mt-4">
                        <a @click="$bvModal.show('show-modal-company')"
                          class="button btn btn-outline-secondary btn-sm float-right mb-2">
                          <i class="fa fa-plus mr-1"></i>
                          Adicionar nova empresa
                        </a>
                      </div>
                    </div>
                    <div v-if="selected_option" class="scrollable-table" style="max-height: 330px">
                      <economic-group-table v-if="selected_option" :items="dataFormCompany.items_taker"
                        @deleteCompany="deleteCompany" @showCompany="showCompany" />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-5">
                  <h6>
                    <div class="form-subheader">
                      <span>DOCUMENTOS <i class="fa fa-angle-right mx-2"></i>
                        <p class="text-dark my-0" style="overflow-wrap: normal">
                          Demonstrativos financeiros
                        </p>
                        <i class="fa fa-asterisk text-warning ml-1"></i>
                      </span>
                    </div>
                  </h6>
                  <div class="row mt-2">
                    <div class="col-md-12 mb-3">
                      <b-tabs>
                        <b-tab v-for="balanceType in balanceTypes" :key="balanceType" :title="getTabTitle(balanceType)"
                          :value="balanceType" class="nav-item">
                          <b-card-text>
                            <div class="border-left border-bottom border-right p-3 tab-content">
                              <upload-data :files="files[
                                isAcceptedDocumentType(balanceType)
                                  ? balanceType
                                  : balanceType.substring(8)
                                ]
                                " :url="urlUpload" :forceRedirect="true" @deleteFile="deleteFile"
                                @uploadFinished="uploadFinished" @showEconomicGroupFiles="showEconomicGroupFiles"
                                :class="{ 'disabled-button': isFormDisabled }" :title="isFormDisabled
                                    ? 'Preencha os campos de Dados do Tomador'
                                    : ''
                                  " :balanceType="balanceType" ref="upload" />
                            </div>
                          </b-card-text>
                        </b-tab>
                      </b-tabs>

                      <observations-table class="mt-4" :observation_data="observation_data"
                        :is_form_disabled="isFormDisabled" :items_observation="dataFormCompany.items_observation"
                        @setObservationItems="setObservationItems" @showClientData="showClientData" />
                    </div>
                    <p class="mt-3 ml-2">
                      <i class="fa fa-asterisk text-warning ml-1"></i>
                      Todos os Balanços e Balancetes devem estar com DRE.
                    </p>
                    <market-capacity :itemsInsurer="dataFormCompany.items_insurer" :isFormDisabled="isFormDisabled"
                      @deleteInsurer="deleteInsurer" @showInsurer="showInsurer" />

                      <div class="col-12">
                        <h6 class="form-subheader mb-3"><span>Seguradoras habilitadas</span></h6>
                        <p>Selecione as seguradoras habilitadas para este tomador</p>

                        <div class="d-flex flex-wrap gap-2">
                          <label
                            v-for="insurer in insurerList"
                            :key="insurer.identifier"
                            class="card card-body border shadow p-3 d-block check-card mb-2 mr-2"
                            style="flex: 0 0 150px;"
                          >
                            <input
                              type="checkbox"
                              :id="'seguradora_habilitada_' + insurer.identifier"
                              :value="insurer.identifier"
                              v-model="dataFormCompany.insurers_enable"
                              class="form-control"
                            />
                            <img :src="insurer.icon" :alt="insurer.name" class="logo-seguradora-enable" />
                          </label>
                        </div>
                      </div>


                    <div class="col-lg-12">
                      <div class="float-right mr-3 mt-4">
                        <button class="btn btn-outline-secondary mr-3" v-if="!is_edit" @click="redirect()">
                          <i class="fa fa-times mr-1"></i>
                          Cancelar
                        </button>
                        <button v-if="is_edit" :disabled="invalid" class="button btn btn-primary mt-4" type="submit">
                          Atualizar Tomador
                          <i class="fa fa-angle-right ml-2"></i>
                        </button>
                        <button v-else :disabled="invalid" class="button btn btn-primary" type="submit">
                          Cadastrar Tomador
                          <i class="fa fa-angle-right ml-2"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <economic-group @setEconomicGroup="setEconomicGroup" :economicGroup="dataFormEconomicGroup" ref="modalCompany" />
    <insurer-modal @setInsurerData="setInsurerData" :dataFormInsurer="dataFormInsurer" />
    <observations-modal @setObservationData="setObservationData" @showClientData="showClientData" />
  </div>
</template>

<script>
import {
  EconomicGroupTable,
  EconomicGroup,
  InsurerModal,
  UploadData,
  ObservationsModal,
  ObservationsTable,
  MarketCapacity,
} from "@/components/Client";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import commonsService from "@/services/commons";
import commomsHelperMixin from "@/mixins/commomsHelper";
import companyService from "@/services/company";
import { mask } from "vue-the-mask";
import balanceTypes from "@/enums/balanceTypes";
import balanceTypesTranslate from "@/enums/translate/balanceTypes";

export default {
  name: "client-store",
  components: {
    EconomicGroupTable,
    EconomicGroup,
    InsurerModal,
    UploadData,
    ValidationObserver,
    ValidationProvider,
    ObservationsModal,
    ObservationsTable,
    MarketCapacity,
  },
  mixins: [commomsHelperMixin],
  data: () => ({
    dataFormCompany: {
      group_name: "",
      cnpj: "",
      company_name: "",
      address: "",
      commercial_id: "",
      items_taker: [],
      items_insurer: [],
      items_observation: [],
      bank: "",
      agency: "",
      current_account: "",
      pix_key: "",
      commercial: "",
      insurers_enable: [],
    },
    selected_option: false,
    single_invoice: 0,
    dataFormEconomicGroup: {
      cnpj: "",
      company_name: "",
      type: 1,
      address: "",
      index: null,
    },
    dataFormInsurer: {
      name: "",
      approved_limit: null,
      available_limit: null,
      total_accumulation: null,
      current_rate: null,
      validity: "",
      ccg: 1,
      appointment: 1,
      status: 0,
      modality: 1,
    },
    is_edit: false,
    loading: false,
    urlUpload: "",
    balanceTypes: balanceTypes,
    balanceTypesTranslate: balanceTypesTranslate,
    files: [],
    balance_type: "",
    first_upload: true,
    observation_data: [],
    is_form_disabled: [],
    wasFilled: false,
    debounceTimeout: null,
    searchQuery: "",
    selectedCommercialData: "",
    commercialOptions: [],
    insurerList: [],
  }),
  directives: {
    mask,
  },
  computed: {
    isFormDisabled() {
      if (this.is_edit) {
        return false;
      }
      return (
        !this.dataFormCompany.group_name ||
        !this.dataFormCompany.cnpj ||
        this.dataFormCompany.cnpj.length < 18 ||
        !this.dataFormCompany.company_name
      );
    },
    selectedCommercial() {
      return (
        this.selectedCommercialData || {
          text: "Digite o nome do comercial responsável...",
        }
      );
    },
    filteredOptions() {
      return this.commercialOptions.filter((option) =>
        option.text.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  created() {
    if (this.getIdFromUrl().length == 36) {
      this.is_edit = true;
      this.showClientData();
      this.showEconomicGroupFiles();
      this.getResponsibleCommercial();
    }
    this.getInsurers()
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateSelectedOption(value) {
      this.selected_option = value;
    },
    redirect() {
      this.$router.push({
        name: "list-client",
      });
    },
    redirectToClient(uid) {
      this.is_edit = true;
      this.$router.push({
        name: "update-client",
        params: { economicGroupID: uid },
      });
    },
    onChangeCompanyCnpj: function (cnpj) {
      if (cnpj.length == 18) {
        this.loading = true;
        cnpj = this.traitCnpj(cnpj);
        commonsService
          .findCnpj(cnpj)
          .then((response) => {
            if (response.data.error) {
              return;
            }
            this.dataFormCompany.company_name = response.data.name;
            this.dataFormCompany.address = response.data.address;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    showClientData(uid = null) {
      uid = uid != null ? uid : this.getIdFromUrl();
      this.loading = true;
      companyService
        .showEconomicGroup(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          const {
            company,
            economicGroups,
            economicGroupInsurers,
            economicGroupObservations,
            economicGroupBankingDetails,
          } = response.data;
          this.dataFormCompany = {
            items_taker: this.mapData(economicGroups),
            items_insurer: economicGroupInsurers,
            items_observation: economicGroupObservations,
            banking_details: economicGroupBankingDetails,
          };
          this.dataFormCompany.cnpj = company.economic_group_cnpj;
          this.dataFormCompany.commercial_id = company.commercial_id;
          this.dataFormCompany.group_name = company.group_name;
          this.dataFormCompany.company_name = company.company_name;
          this.dataFormCompany.bank =
            this.dataFormCompany?.banking_details?.bank;
          this.dataFormCompany.agency =
            this.dataFormCompany?.banking_details?.agency;
          this.dataFormCompany.current_account =
            this.dataFormCompany?.banking_details?.current_account;
          this.dataFormCompany.pix_key =
            this.dataFormCompany?.banking_details?.pix_key;
          this.dataFormCompany.insurers_enable = response.data?.economicGroupInsurersEnable?.map(insurer => insurer.identifier)

          this.dataFormCompany.address = company.address;
          this.single_invoice = company.single_invoice;
          this.selected_option = this.dataFormCompany.items_taker.length > 0;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInsurers() {
      this.loading = true;
      commonsService
        .getAllInsurersWithIntegration()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.insurerList = response.data;
          if(!this.edit){
            this.dataFormCompany.insurers_enable = this.insurerList.map(insurer => insurer.identifier)
          }
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mapData(data) {
      return data.map((group) => {
        return {
          cnpj: group.company_cnpj,
          company_name: group.company_name,
          type: group.economic_group_type_id,
          index: group.id,
        };
      });
    },
    saveEconomicGroup(mustRedirect = false) {
      if (this.is_edit) {
        this.updateEconomicGroup(mustRedirect);
      } else {
        this.storeCompany(mustRedirect);
      }
    },
    storeCompany(mustRedirect = false) {
      this.dataFormCompany.selected_option = this.selected_option;
      this.dataFormCompany.single_invoice = this.single_invoice;
      this.loading = true;
      companyService
        .storeCompanyGroup(this.dataFormCompany)
        .then((response) => {
          if (response.data.error) {
            return;
          }
          this.is_edit ? this.showClientData() : this.saveFiles(response.data);
          if (mustRedirect) {
            this.$nextTick(() => {
              this.redirect();
            });
          }
        })
        .catch(() => {
          this.showError(
            "Não foi possível criar ou atualizar dados do Tomador."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateEconomicGroup(mustRedirect = false) {
      this.dataFormCompany.selected_option = this.selected_option;
      this.dataFormCompany.single_invoice = this.single_invoice;
      this.loading = true;
      companyService
        .updateEconomicGroup(this.getIdFromUrl(), this.dataFormCompany)
        .then((response) => {
          if (response.data.error) {
            return;
          }
          this.showClientData();
          if (mustRedirect) {
            this.redirect();
          }
        })
        .catch(() => {
          this.showError(
            "Não foi possível criar ou atualizar dados do Tomador."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setEconomicGroup(data) {
      if(data.index) {
          this.updateGroup(data);
      } else {
          this.createTaker(data);
      }
    },
    updateGroup(data){
      companyService
        .updateGroup(this.getIdFromUrl(), data)
        .then((response) => {
          if (response.data.error) {
            return;
          }
        })
        .catch(() => {
          this.showError(
            "Não foi possível criar ou atualizar compania do grupo econômico."
          );
        })
        .finally(() => {
          this.showClientData();
          this.$nextTick(() => {
            this.$refs.modalCompany?.closeModal();
          });
        });
    },
    createTaker(data){
      companyService
        .registerTaker(this.getIdFromUrl(), data)
        .then((response) => {
          if (response.data.error) {
            return;
          }
        })
        .catch(() => {
          this.showError(
            "Não foi possível criar ou atualizar compania do grupo econômico."
          );
        })
        .finally(() => {
          this.showClientData();
          this.$nextTick(() => {
            this.$refs.modalCompany?.closeModal();
          });
        });
    },
    showCompany(data) {
      this.dataFormEconomicGroup.cnpj = data.cnpj;
      this.dataFormEconomicGroup.company_name = data.company_name;
      this.dataFormEconomicGroup.type = data.type;
      this.dataFormEconomicGroup.index = data.index;
      this.dataFormEconomicGroup.old_cnpj = this.dataFormEconomicGroup.cnpj;
      this.$bvModal.show("show-modal-company");
    },
    deleteCompany(data) {
      this.loading = true;
      companyService
        .deleteCompany(data.index)
        .then((response) => {
          if (response.data.error) {
            return;
          }
        })
        .catch(() => {
          this.showError(
            "Não foi possível apagar compania do grupo economico."
          );
        })
        .finally(() => {
          this.showClientData();
          this.loading = false;
        });
    },
    setInsurerData(data) {
      if (data.index != null) {
        this.updateInsurer(data);
      } else {
        this.addNewInsurer(data);
      }
    },
    setObservationData(data) {
      this.observation_data = data;
    },
    setObservationItems(data) {
      this.dataFormCompany.items_observation = data;
    },
    addNewInsurer(data) {
      this.loading = true;
      companyService
        .registerInsurer(this.getIdFromUrl(), data)
        .then((response) => {
          if (response.data.error) {
            this.showError(
              "Erro ao cadastrar seguradora: " + response.data.message
            );
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível cadastrar a seguradora.");
        })
        .finally(() => {
          this.showClientData();
          this.loading = false;
        });
    },

    showInsurer(index) {
      this.dataFormInsurer = {
        ...this.dataFormCompany.items_insurer[index],
      };
      this.dataFormInsurer.index = index;
      this.$bvModal.show("show-modal-insurer");
    },
    updateInsurer(data) {
      if (this.is_edit) {
        this.loading = true;
        companyService
          .updateInsurer(data.id, data)
          .then((response) => {
            if (response.data.error) {
              return;
            }
          })
          .catch(() => {
            this.showError(
              "Não foi possível atualizar seguradora do grupo economico."
            );
          })
          .finally(() => {
            this.showClientData();
          });
      } else {
        Object.assign(this.dataFormCompany.items_insurer[data.index], data);
      }
    },
    deleteInsurer(data) {
      if (this.is_edit) {
        this.loading = true;
        companyService
          .deleteInsurer(data.item.id)
          .then((response) => {
            if (response.data.error) {
              return;
            }
          })
          .catch(() => {
            this.showError(
              "Não foi possível apagar seguradora do grupo economico."
            );
          })
          .finally(() => {
            this.showClientData();
          });
      } else {
        this.dataFormCompany.items_insurer.splice(data.index, 1);
      }
    },
    showEconomicGroupFiles() {
      this.loading = true;
      companyService
        .showEconomicGroupFiles(this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.clearUploadState();
          this.files = [];
          this.files = response.data;
        })
        .catch(() => {
          this.showError(
            "Não foi possível visualizar os arquivos dos grupos economicos."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    uploadFinished(balance_type) {
      this.balance_type = balance_type;
      if (!this.is_edit && this.first_upload) {
        this.first_upload = false;
        this.storeCompany();
      }
      if (this.is_edit) {
        this.saveFiles(this.getIdFromUrl());
      }
    },
    saveFiles(uid) {
      this.urlUpload = `/api/v1/economic-group-file/${uid}/files/${this.balance_type}`;
      this.$nextTick(() => {
        this.$refs.upload.forEach((element) => {
          if (element.$refs.dropzone.getAcceptedFiles().length > 0) {
            element.$refs.dropzone.processQueue();
            return;
          }
        });
      });
      this.is_edit ? "" : this.redirectToClient(uid);
    },
    deleteFile(fileId) {
      if (this.is_edit) {
        this.loading = true;
        companyService
          .deleteEconomicGroupFile(fileId)
          .then((response) => {
            if (response.data.error) {
              this.showError(response.data.message);
              return;
            }
          })
          .catch(() => {
            this.showError("Erro ao apagar o arquivo do grupos economico.");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getTabTitle(balanceType) {
      if (this.isAcceptedDocumentType(balanceType)) {
        return this.getEnumKeyByEnumValue(
          this.balanceTypesTranslate,
          balanceType
        );
      }
      return "Balanço " + balanceType.substring(8);
    },
    isAcceptedDocumentType(balanceType) {
      return (
        balanceType == "CORPORATE-DOCUMENTATION" ||
        balanceType == "APPOINTMENT" ||
        balanceType == "OTHERS"
      );
    },
    clearUploadState() {
      const keys = Object.keys(balanceTypes);
      for (let i = 0; i < keys.length; i++) {
        this.$refs.upload[i].clearUploadState();
      }
    },
    truncateText(text, maxChar = 28) {
      return text.length > maxChar ? `${text.substring(0, maxChar)}...` : text;
    },
    debounceCheckAndSave() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.checkAndSave();
      }, 500);
    },
    async checkAndSave() {
      if (!this.isFormDisabled && !this.wasFilled) {
        this.wasFilled = true;
        if (!this.is_edit) {
          this.loading = true;
          this.dataFormCompany.selected_option = this.selected_option;
          this.dataFormCompany.single_invoice = this.single_invoice;
          this.loading = true;
          companyService
            .storeCompanyGroup(this.dataFormCompany)
            .then((response) => {
              if (response.data.error) {
                return;
              }
              this.redirectToClient(response.data.id);
            })
            .catch(() => {
              this.showError(
                "Não foi possível criar ou atualizar dados do Tomador."
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    selectCommercial(option) {
      this.$set(this.dataFormCompany, "commercial_id", option.value);
      this.selectedCommercialData = option;
      this.searchQuery = "";
    },
    getResponsibleCommercial() {
      companyService
        .getResponsibleCommercial()
        .then((response) => {
          if (response.data.error) {
            return;
          }
          this.commercialOptions = response.data;
          this.$nextTick(() => {
            this.selectedCommercialData =
              this.commercialOptions.find(
                (option) => option.value === this.dataFormCompany.commercial_id
              ) || null;
          });
        })
        .catch(() => {
          this.showError(
            "Não foi possível receber lista de comerciais responsáveis do grupo econômico."
          );
        })
        .finally(() => {
          this.showClientData();
          this.$nextTick(() => {
            this.$refs.modalCompany?.closeModal();
          });
        });
    },
  },
  watch: {
    "dataFormCompany.group_name": "debounceCheckAndSave",
    "dataFormCompany.cnpj": "debounceCheckAndSave",
    "dataFormCompany.company_name": "debounceCheckAndSave",
    "dataFormCompany.commercial_id"(newVal) {
      this.selectedCommercialData =
        this.commercialOptions.find((option) => option.value === newVal) ||
        null;
    },
  },
};
</script>

<style scoped>
::v-deep(#dropdown-comercial .dropdown-menu) {
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff !important;
  padding-top: 0;
}

#dropdown-comercial>>>.btn {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  color: #495057 !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
}
</style>
