<template>
  <div class="modal">
    <b-modal
      id="commission-modal"
      ref="modal"
      size="md"
      class="mt-4"
      @hidden="resetModal"
      hide-footer
    >
      <template #modal-title>
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <p class="text-muted mb-0 small mt-1">
          {{ isEditing ? "Edite a comissão existente" : "Crie uma nova comissão" }}
        </p>
      </template>

      <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>

      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit(save)">
          <div class="card mt-1 px-3 py-2">
            <div class="col-md-12 mb-3">
              <validation-provider name="Seguradora" rules="required" v-slot="validationContext" vid="insurer">
                <label class="dbo-ui">Seguradora</label>
                <b-form-select
                  v-model="commission.insurer_identifier"
                  :options="insurerOptions"
                  :state="getValidationState(validationContext)"
                ></b-form-select>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
            <div class="col-md-12 mb-3">
              <validation-provider
                name="Percentual da Comissão"
                rules="required"
                v-slot="validationContext"
                vid="percentage"
              >
                <label class="dbo-ui">Percentual da Comissão</label>
                <b-form-input
                  v-model="commission.percentage"
                  :state="getValidationState(validationContext)"
                  placeholder="Digite o percentual"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>
          <div class="row border-top mt-4">
            <div class="col-12 col-lg-12">
              <button class="btn btn-primary mx-2 my-2 float-right" :disabled="invalid" type="submit">
                Salvar
              </button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import policyService from "@/services/policy";

export default {
  name: "CommissionModal",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      isEditing: false,
      commission: {
        id: null,
        percentage: null,
        insurer_identifier: null,
      },
      insurerOptions: [],
    };
  },
  computed: {
    modalTitle() {
      return this.isEditing ? "Editar Comissão" : "Criar Comissão";
    },
  },
  created() {
    this.getInsurers();
  },
  methods: {
  open(commission = null) {
    if (commission) {
      this.commission = {
        id: commission.id,
        percentage: commission.percentage,
        insurer_identifier: commission.insurer,
      };
      this.isEditing = true;
    }
    this.$bvModal.show("commission-modal");
  },

    close() {
      this.$bvModal.hide("commission-modal");
    },
    save() {
      this.$emit("save", this.commission);
      this.close();
    },
    resetModal() {
      this.commission = { id: null, percentage: null, insurer: null };
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getInsurers() {
      policyService
        .getInsurers()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.insurerOptions = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de seguradoras.");
        });
    },
  },
};
</script>
