<template>
  <div class="page-container">
    <div class="page-content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row space-around mb-3 d-flex">
          <div class="col-md-3">
            <h1 class="text-white">
              <i class="mdi mdi-account-group"></i> Usuários
            </h1>
          </div>
          <div class="col-9 d-flex justify-content-end">
            <div>
              <button
                v-if="$can('user-store')"
                @click="redirect('create-edit-user')"
                class="ml-auto btn btn-outline-white mr-2"
              >
                <i class="fa fa-plus-circle"></i>
                <span class="d-none d-md-inline"> Criar Usuário</span>
              </button>
              <button
                v-if="$can('user-store')"
                @click="showModalInvite()"
                class="ml-auto btn btn-outline-white mr-2"
              >
                <i class="fas fa-reply"></i>
                <span class="d-none d-md-inline"> Convidar</span>
              </button>
              <button
                type="button"
                class="button btn btn-outline-white"
                v-b-toggle.collapse-2
              >
                <span class="mr-2">Filtros</span>
                <i class="fa fa-angle-up status-icon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mb-3">
        <div class="col-12 col-lg-5">
          <b-collapse id="collapse-2" class="w-100">
            <b-input-group>
              <!-- Campo de Filtro -->
              <b-form-input
                v-model="filter"
                @keyup.enter="applyFilter"
                placeholder="Digite o que deseja buscar"
              ></b-form-input>

              <!-- Botão de Buscar -->
              <b-input-group-prepend>
                <span
                  class="input-group-text cursor-pointer"
                  @click="applyFilter"
                  v-b-tooltip.hover
                  title="Buscar"
                >
                  <i class="fa fa-search"></i>
                </span>
              </b-input-group-prepend>

              <!-- Botão de Limpar -->
              <b-input-group-append>
                <span
                  class="input-group-text cursor-pointer"
                  @click="clearFilter"
                  v-b-tooltip.hover
                  title="Limpar"
                >
                  <i class="fa fa-times"></i>
                </span>
              </b-input-group-append>
            </b-input-group>
          </b-collapse>
        </div>
      </div>

      <!-- Alert Messages -->
      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>
      <div v-if="inviteMessage" class="col-12">
        <div class="alert-custom alert-success-custom break-line">
          {{ inviteMessage }}
        </div>
      </div>

      <!-- Loading Overlay -->
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>

      <!-- User Table -->
      <div
        class="gray-table table-responsive"
        style="margin-bottom: 50px; width: 100%"
      >
        <table class="custom-table custom-table-quotation no-wrap">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Documento</th>
              <th>Telefone</th>
              <th>Email</th>
              <th>Data de Criação</th>
              <th>Cargo</th>
              <th>Acesso ao Painel</th>
              <th>Acesso ao Email de Apólice</th>
              <th class="text-center custom-actions-th">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.document }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <td>
                {{
                  item.created_at ? formatDateTimeLocal(item.created_at) : "---"
                }}
              </td>
              <td>{{ item.role_name }}</td>
              <td class="text-center">
                <i
                  class="fas fa-check-circle co"
                  v-if="item.has_access_panel"
                ></i>
                <i class="fas fa-ban" v-else></i>
              </td>
              <td class="text-center">
                <i
                  class="fas fa-check-circle co"
                  v-if="item.has_access_policy_mail"
                ></i>
                <i class="fas fa-ban" v-else></i>
              </td>
              <td class="actions text-right">
                <!-- Ícone de Clonar -->
                <button
                  class="btn btn-action-gray"
                  v-if="$can.any(['user-edit', 'user-update'])"
                  @click="
                    redirect('create-edit-user', null, { clone: item.id })
                  "
                  v-b-tooltip.hover
                  title="Clonar Usuário"
                >
                  <i class="far fa-copy"></i>
                </button>
                <!-- Ícone de Editar -->
                <button
                  class="btn btn-action-gray"
                  v-if="$can.any(['user-edit', 'user-update'])"
                  @click="redirect('create-edit-user', { userId: item.id })"
                  v-b-tooltip.hover
                  title="Editar"
                >
                  <i class="fas fa-pen"></i>
                </button>
                <!-- Ícone de Excluir -->
                <button
                  class="btn btn-action-gray"
                  v-if="$can.any(['user-edit', 'user-update']) && isMaster()"
                  @click="showModalDelete(item)"
                  v-b-tooltip.hover
                  title="Excluir"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Paginação" class="md-3" style="padding-left: 2.5%">
          <b-pagination
            @page-click="pageChanged"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </nav>
      </div>
    </div>
    <div class="modal">
      <b-modal
        id="show-modal-delete"
        ref="showModalDelete"
        class="mt-4"
        size="sm"
        centered
        hide-footer
      >
        <br />
        <template #modal-title> Deseja excluir esse usuário? </template>
        <div class="mb-5 pb-2">
          <p class="my-4">
            Deseja excluir o usuário "{{ data.name }}" com documento
            {{ data.document }}?
          </p>
        </div>
        <div>
          <a
            @click="$bvModal.hide('show-modal-delete')"
            class="btn btn-outline-secondary"
            ><i class="fa fa-angle-left"></i> Voltar</a
          >
          <a class="btn btn-md btn-danger ml-2" @click="deleteUser(data.id)"
            ><i class="fas fa-times"></i> Confirmar</a
          >
        </div>
      </b-modal>
    </div>
    <!-- Modal Invite -->
    <div class="modal">
      <b-modal
        id="show-modal-invite"
        ref="showModalInvite"
        class="mt-4"
        size="md"
        centered
        hide-footer
      >
        <template #modal-title> Convidar usuário </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
          <b-form
            @submit.stop.prevent="handleSubmit(inviteUser)"
            class="form"
            autocomplete="off"
          >
            <div class="row">
              <div v-if="alertMessage" class="col-12">
                <b-alert show :variant="alertVariant">{{
                  alertMessage
                }}</b-alert>
              </div>

              <div class="col-12">
                <label class="dbo-ui"
                  >Informe o e-mail que você deseja convidar:</label
                >
              </div>

              <div
                class="col-12"
                v-for="(email, idx) in emailInvite"
                v-bind:key="idx"
              >
                <div class="pb-2">
                  <validation-provider
                    name="Email"
                    rules="required|email"
                    v-slot="validationContext"
                    vid="email"
                  >
                    <div class="d-flex justify-content-center group-button">
                      <b-form-input
                        type="email"
                        v-model="emailInvite[idx]"
                        :name="`emailInvite[${idx}].email`"
                        :state="getValidationState(validationContext)"
                        placeholder="Preencha o e-mail"
                      ></b-form-input>

                      <button
                        class="btn btn-outline-primary"
                        @click="removeEmailInvite(idx)"
                      >
                        <i class="fa fa-close"></i>
                      </button>
                    </div>
                    <div class="error">
                      <b-form-invalid-feedback>{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </div>
                  </validation-provider>
                </div>
              </div>

              <div class="col-12 d-flex justify-content-end mb-3">
                <a class="btn btn-outline-primary btn-sm" @click="addEmailInput"
                  >Adicionar e-mail <i class="fa fa-plus ml-2"></i
                ></a>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <a
                @click="$bvModal.hide('show-modal-invite')"
                class="btn btn-outline-secondary"
                ><i class="fa fa-angle-left"></i> Voltar</a
              >

              <button class="btn btn-md btn-primary" :disabled="invalid">
                <i class="fas fa-reply"></i> Convidar
              </button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import userService from "@/services/user";
import authenticationService from "@/services/authentication";
import dateFormatter from "@/mixins/dateFormatter";

export default {
  name: "list-users",
  mixins: [dateFormatter],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    items: [],
    filter: null,
    data: [],
    loading: false,
    emailInvite: [],
    inviteMessage: "",
    totalRows: 0,
  }),
  created() {
    this.getUsers();
  },
  computed: {
    fields() {
      var columns = [
        {
          key: "name",
          label: "Nome",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui rounded-top",
        },
        {
          key: "document",
          label: "Documento",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "phone",
          label: "Telefone",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "email",
          label: "Email",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "created_at",
          label: "Data de criação",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "role_name",
          label: "Cargo",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "has_access_panel",
          label: "Acesso ao Painel",
          tdClass: "font-14 justify-content-center",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "has_access_policy_mail",
          label: "Acesso ao Email de Apólice",
          tdClass: "font-14 justify-content-center",
          thClass: "table-pretty dbo-ui",
        },
      ];

      if (this.isMaster()) {
        columns.push({
          key: "utm",
          label: "UTM",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        });
      }

      columns.push({
        key: "id",
        label: "Ação",
        tdClass: "column-size-share font-12",
        thClass: "table-pretty dbo-ui rounded-top ",
      });
      return columns;
    },
  },
  methods: {
    getUsers() {
      this.resetAlert();
      this.loading = true;
      userService
        .users({
          page: this.currentPage,
          per_page: this.perPage,
          filter: this.filter,
        })
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.items = response.data.data;
          this.totalRows = response.data.total;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os usuários.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    redirect(routeName, params = null, query = null) {
      this.$router.push({ name: routeName, params: params, query: query });
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    showModalDelete(data) {
      this.data = data;
      this.$refs.showModalDelete.show();
    },
    deleteUser(user_id) {
      this.loading = true;
      userService
        .destroy(user_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.$refs.showModalDelete.hide();
          this.getUsers();
        })
        .catch(() => {
          this.showError("Não foi possível deletar o usuário.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    showModalInvite() {
      this.emailInvite = [""];
      this.$refs.showModalInvite.show();
    },
    inviteUser() {
      this.resetAlert();
      userService
        .invite(this.emailInvite)
        .then((response) => {
          var data = response.data.data;
          console.log(response);

          var msg = "";
          if (data.existings.length) {
            msg += "- Usuários existentes:\n";
            msg += data.existings.map((obj) => obj.email).join(", ") + "\n";
          }

          if (data.inviteds.length) {
            msg += "- Convites enviados:\n";
            msg += data.inviteds.map((obj) => obj.email).join(", ") + "\n";
          }

          this.inviteMessage = msg;
          this.$refs.showModalInvite.hide();
          return;
        })
        .catch(() => {
          this.showError("Não foi possível enviar os convites.");
        });
    },
    addEmailInput() {
      this.emailInvite.push("");
    },
    removeEmailInvite(idx) {
      this.emailInvite.splice(idx, 1);
      this.$refs.observer.validate();
    },
    pageChanged(bvEvent, pageNumber) {
      this.currentPage = pageNumber;
      this.getUsers();
    },
    clearFilter() {
      this.currentPage = 1;
      this.filter = "";
      this.getUsers();
    },
    applyFilter() {
      this.currentPage = 1;
      this.getUsers();
    },
  },
};
</script>

<style>
</style>
