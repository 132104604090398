import { render, staticRenderFns } from "./MarketCapacity.vue?vue&type=template&id=38533877&scoped=true&"
import script from "./MarketCapacity.vue?vue&type=script&lang=js&"
export * from "./MarketCapacity.vue?vue&type=script&lang=js&"
import style0 from "./MarketCapacity.vue?vue&type=style&index=0&id=38533877&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38533877",
  null
  
)

export default component.exports