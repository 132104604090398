<template>
  <div>
    <h5>
      <div class="form-subheader">
        <span>Observações do Tomador </span>
      </div>
    </h5>
    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
      <div class="d-flex justify-content-center mb-2">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
    <div>
    <a
      v-if="!is_form_disabled"
      @click="$bvModal.show('show-modal-observations')"
      class="button btn btn-outline-secondary btn-sm mb-3 float-right"
    >
      <i class="fa fa-plus mr-1"></i>
      Adicionar Observação 
    </a>
    <a
      v-else
      class="button btn btn-outline-secondary btn-sm mb-3 float-right disabled"
      style="pointer-events: none; opacity: 0.5; cursor: not-allowed;"
    >
      <i class="fa fa-plus mr-1"></i>
      Adicionar Observação
    </a>
    </div>
    <label class="dbo-ui mb-lg-0 font-weight-bold ml-2" for="">Observações</label>
    <b-table :fields="fields" :items="items" class="financial-statement-table" style="background-color: #f8f8f8">
      <template #cell(actions)="row">
        <div class="centered-header">
          <a class="text-secondary" @click="deleteObservation(row)">
            <i class="fas fa-trash mx-1 icon-btn"></i>
          </a>
        </div>
      </template>
      <template #cell(date)="data">
        <span class="centered-cell">{{
          data.value != "" ? formatDateLocal(data.value) : "- - -"
        }}</span>
      </template>
    </b-table>
    <div class="modal">
      <b-modal
        id="show-modal-delete-observations"
        ref="showModalDeleteObservations"
        size="sm"
        class="mt-4"
        hide-footer
        centered
      >
        <br />
        <template #modal-title>
          <h5 class="modal-title">Deseja apagar essa observação ?</h5>
        </template>
        <div class="text-center font-14 dbo-ui">
          Deseja excluir a observação criada por {{ observation.author }} na data
          {{ observation.date }}
        </div>
        <button
          @click="$bvModal.hide('show-modal-delete-observations')"
          class="btn btn-outline-secondary btn-lg text-uppercase my-4 px-4 mr-1"
        >
          <i class="fa fa-angle-left mr-2"></i
          ><span class="d-none d-md-inline-block"> Voltar</span>
        </button>
        <button
          @click="destroyObservation(observation.id)"
          class="btn btn-primary w-50 my-4 float-right"
        >
          Excluir
          <i class="fa fa-angle-right ml-2"></i>
        </button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import dateFormatterMixin from "@/mixins/dateFormatter";
import commomsHelperMixin from "@/mixins/commomsHelper";
import companyService from "@/services/company";
import commonsTypes from "@/enums/commonsTypes";

export default {
  name: "ObservationsTable",
  emits: ["setObservationItems", "showClientData"],
  mixins: [dateFormatterMixin, commomsHelperMixin],
  data: () => ({
    items: [],
    is_edit: false,
    observation: {},
    loading: false,
    commonsTypes: commonsTypes,
  }),
  props: {
    observation_data: {
      default: "",
    },
    items_observation: {
      default: [],
    },
    is_form_disabled: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    observation_data: {
      handler(newData) {
        this.items.push({
          author: newData.author,
          date: newData.date,
          text: newData.text,
        });
        this.returnObservationItems();
      },
      deep: true,
    },
    items_observation: {
      handler(newData) {
        this.items = newData;
      },
    },
  },
  created() {
    if (this.getIdFromUrl().length == this.commonsTypes.URL_LENGHT) {
      this.is_edit = true;
    }
  },
  computed: {
    fields() {
      var columns = [
        {
          key: "author",
          label: "usuário",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "date",
          label: "data",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "text",
          label: "observação",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "actions",
          label: "",
          tdClass: "font-14 ",
          thClass: "table-pretty dbo-ui centered-header",
        },
      ];
      return columns;
    },
  },

  methods: {
    destroyObservation(id) {
      this.loading = true;
      companyService
        .deleteEconomicGroupObservation(id)
        .then((response) => {
          if (response.data.error) {
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível apagar observação.");
        })
        .finally(() => {
          this.$bvModal.hide("show-modal-delete-observations");
          this.loading = false;
          this.$emit("showClientData");
        });
    },
    deleteObservation(data) {
      if (this.is_edit) {
        this.observation = data.item;
        this.$bvModal.show("show-modal-delete-observations");
      } else {
        this.items.splice(data.index, 1);
      }
    },
    returnObservationItems() {
      this.$emit("setObservationItems", this.items);
    },
  },
};
</script>

<style></style>
